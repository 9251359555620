.SingleCard{

}

.SingleCard-Year{
    color: var(--black);
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding-inline: 5px;
    background-color: var(--white);
    border-radius: 10px;
}

.InnerCard{
    width: 300px;
    height: 200px;
    border-radius: 20px;
    border: 1px solid rgba(200 200 200 / 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #eee;
    text-shadow: 0 1px 0 #999;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.InnerCard > * {
    filter: blur(0px);
    overflow: hidden;
}

.InnerCard:hover {
    transform: scale(1.1);
}

.CardImageHover{
    display: none;
}

.InnerCard:hover > .CardImageHover{
    display: block;
    z-index: 10;
}

.CardImage{
    width: 300px;
    height: 200px;
    position: absolute;
    object-fit: cover;
    border-radius: 20px;
}

