.Portfolio{
    background-color: var(--black);
    color: var(--greyish);
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100vw;
    height: fit-content;
}

.Portfolio > *{
    margin-inline: auto;
    width: fit-content;
}


.CardContainer{
    max-width: 1300px;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;
    margin-top: 48px;
}
