.Home{
    width: 100vw !important;
    position: relative;
    overflow: hidden;
    transform: scale(1);
}

.Home-TopContainer{
    margin-top: 48px;
    margin-bottom: 48px;
    margin-inline: auto;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
}


.Home-TopContainer-Left{
    min-width: 275px;
    height: 350px !important;
}

.Home-TopContainer-LeftImage{
    position: absolute;
    min-width: 275px !important;
    width: 275px;
    height: 350px !important;
    transition: all 0.2s ease-in-out;
    background-image: url("/public/Aleksandr.jpg");
    background-size: cover;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #000000;
    border-radius: 20px;
}

.Home-TopContainer-LeftImage:hover{
    transform: scale(1.1);
}


.Home-TopContainer-LeftImage{
    border-radius: 20px;
    border: solid 1px #000000;
}


.Home-TopContainer-Left > img {
    height: 350px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    overflow: visible;
    transition: all 0.2s ease-in-out;
}


.Home-TopContainer-Left:hover > .IamACircle{
    height: 2100px;
    width: 2200px;
    animation: spin 40s linear infinite;
}

.Home-TopContainer-Left:hover > .IamACircleButBlue{
    height: 2150px;
    width: 2150px;
    margin-top: -1700px;
    margin-left: -1700px;
    animation: spin-negative 40s linear infinite;
}



.IamACircle, .IamACircleButBlue{
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: all 1.2s ease-in-out;
    z-index: -10;
}

.IamACircleButBlue{
    margin-top: 300px;
    margin-left: 200px;
}

.Home-TopContainer-Right {
    margin-left: auto;
    margin-right: 30px;
    color: var(--black);
}

@media screen and (max-width: 768px) {
    .Home-TopContainer{
        flex-direction: column;
        gap: 24px;
    }
    
    .Home-TopContainer > * {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    
}


@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

@-moz-keyframes spin-negative { 
    100% { -moz-transform: rotate(-360deg); } 
}
@-webkit-keyframes spin-negative { 
    100% { -webkit-transform: rotate(-360deg); } 
}
@keyframes spin-negative { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}